import * as React from "react";
import { observer } from "mobx-react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { withStores } from "stores";
import { RecentActivitiesModuleProps } from "./typings";
import { RecentActivitiesModule as RecentActivities } from "@shared-ui/retail-recent-activities";
import { abbreviatedPageId } from "src/components/utility/PageIdUtil";

export const RecentActivitiesModule = withStores("context")(
  observer((props: RecentActivitiesModuleProps) => {
    const { templateComponent, context } = props;

    if (!templateComponent?.config) {
      return null;
    }

    const { contentSize, typeFilter, entity, containerType, headingSize } = templateComponent.config;
    const regionId = context.searchContext?.location?.id ? context.searchContext.location.id.toString() : null;

    return (
      <Viewport>
        <ViewSmall>
          <RecentActivities
            inputs={{
              contentSize,
              typeFilter,
              regionId,
              containerType,
              discoveryRecentActivityContext: {
                outputLineOfBusiness: entity,
                pageId: abbreviatedPageId(context),
              },
            }}
            titleProps={{ size: headingSize ? headingSize : 5, tag: "h2" }}
          />
        </ViewSmall>
        <ViewMedium>
          <RecentActivities
            inputs={{
              contentSize,
              typeFilter,
              regionId,
              containerType,
              discoveryRecentActivityContext: {
                outputLineOfBusiness: entity,
                pageId: abbreviatedPageId(context),
              },
            }}
            titleProps={{ size: headingSize ? headingSize : 4, tag: "h2" }}
          />
        </ViewMedium>
      </Viewport>
    );
  })
);

export default RecentActivitiesModule;
